
import { IStudent } from "@digitale-lernwelten/ugm-client-lib";
import configuration from "../../../configuration";
import { showModal } from "../../modal/modal";
import { getCourseStudents, getResultSuccessPercentage, getTaskResults, getTaskScore, getUserResults, getUserTaskResults, syncXApi } from "../../user-group-management";
import { LearningGoal, compareTaskId, getLearningGoal, showTeacherStudentTaskDetails } from "../training";
import { getPageLearningGoal } from "./xapi";

import "./module-results.css";

export const getWorkingTaskType = (wt: HTMLElement) => {
	const ex = wt.querySelector(`section[content-type="exercise"]`);
	if(ex && (ex.getAttribute("input-type") !== "ka")){
		return "exercise";
	}
	const taskId = wt.querySelector(`section[content-type="embedding"] lazy-iframe, section[content-type="embedding"] iframe`)?.getAttribute("src") || "";
	if(taskId){
		return "embedding";
	}
	return "info";
};

export const getStudentResults = (student: IStudent, goal: LearningGoal, tasks: Task[]) => {
	const res = tasks.map(task => getStudentTaskResults(student, goal, task));
	const resLen = res.reduce((a,b) => a + (b ? 1 : 0),0);
	const totalPercent = res.reduce((a,b) => a + (b?.percent || 0), 0) / resLen;
	const totalTries = res.reduce((a,b) => a + (b?.tries || 0), 0);
	const finished = res.every(c => c?.tries);
	return {
		name: student.reminder || student.loginName,
		id: student.id,
		finished,
		res,
		totalPercent,
		totalTries
	};
};

export const getStudentTaskResults = (student: IStudent, goal: LearningGoal, task: Task) => {
	const raw = getUserResults(student, goal);
	const events = raw.events.filter(e => compareTaskId(e.taskId, task.taskId));
	if(events.length <= 0){
		return {
			percent: 0,
			tries: 0,
			taskScore: 0,
			taskId: "",
			type: task.type
		};
	}
	const event = events[events.length-1];
	const percent = getResultSuccessPercentage([event]);
	const taskScore = getTaskScore(events);
	return {
		percent,
		taskScore,
		tries: events.length,
		taskId: task.taskId,
		type: task.type
	};
};

export interface Task {
	i: number;
	taskId: string;
	type: string;
}

export const getWorkingTaskTasks = (section: HTMLElement) => {
	const tasks = Array.from(section.querySelectorAll<HTMLElement>("working-task"))
		.filter(wt => !wt.querySelector("working-task-results"))
		.map((wt, i) => {
			const taskId = wt.querySelector(`section[content-type="embedding"] lazy-iframe`)?.getAttribute("src") || "";
			return <Task>{
				i,
				taskId,
				type: getWorkingTaskType(wt)
			};
		});
	const chapter = (section.getAttribute("chapter-number") || "");
	return {
		tasks,
		chapter
	};
};

/*
const getChapterNumber = (e: HTMLElement | null, m = 0): number => {
	return e
		? getChapterNumber(e.parentElement, Math.max(m, parseInt(e.getAttribute("chapter-number") || "0")))
		: m;
};
*/

const getAllPageTasks = () => {
	const ret: Record<string, Task[]> = {};
	const arr = Array.from(document.querySelectorAll<HTMLElement>(`section[content-type="working-tasks"]`)).map(getWorkingTaskTasks);
	for(const a of arr){
		ret[a.chapter] = a.tasks;
	}

	/*
	for(const e of document.querySelectorAll<HTMLElement>(`section[content-type="exercise"] lazy-iframe`)){
		const chapter = getChapterNumber(e);
		if(!ret[chapter]){
			ret[chapter] = [];
		}
		const taskId = e.getAttribute("src") || "";
		if(!taskId){
			continue;
		}
		const task = <Task>{
			i: ret[chapter].length,
			taskId,
			type: "embedding"
		};
		ret[chapter].push(task);
	}
	*/

	return Object.keys(ret)
		.filter(a => ret[a].length)
		.sort((a,b) => parseInt(a) - parseInt(b))
		.map(chapter => ({
			chapter: `${chapter}`,
			tasks: ret[chapter]
		}));
};

export const resultTableHeaderRow = (rows:number, suffix:string, emptyRows = 1) => {
	let empty = "";
	for(let i=0;i<emptyRows;i++){
		empty += "<th></th>";
	}
	return empty + [...Array(rows).keys()].map((_,i) => `<th>${i+1}${suffix}</th>`).join("");
};

export const resultTableTaskCell = (tasks: Task[], maxTasks: number, cb: (task:Task, i:number) => string):string => {
	return [...Array(maxTasks).keys()].map((_,i) => {
		if(i >= tasks.length){
			return '<td class="task-out-of-bounds"></td>';
		}
		const cbRes = cb(tasks[i], i);
		if(cbRes){
			return cbRes;
		}
		const type = tasks[i].type;
		switch(type){
		default:
			return "";
		case "exercise":
			return `<td class="task-exercise">Text</td>`;
		case "info":
			return `<td class="task-info">Info</td>`;
		}
	}).join("");
};

export const refreshChapterResults = async (modal: HTMLElement, chapterId: string) => {
	await syncXApi();
	if(!modal){
		return;
	}
	const ele = modal.querySelector<HTMLElement>(".module-results");
	if(!ele){
		return;
	}
	const cat = getPageLearningGoal();
	const goal = await getLearningGoal(cat);
	if(!goal){
		throw new Error(`Unknown Learning Goal ${cat}`);
	}
	const students = await getCourseStudents();
	const allTasks = getAllPageTasks();
	const curChapter = allTasks.find(v => v.chapter === chapterId);
	if(!curChapter){
		throw new Error("Can't find chapter");
	}
	const maxTasks = curChapter.tasks.length;

	const taskSuffix = configuration.target === "netbook" ? ". Schritt" : ". Teil";
	const thead = resultTableHeaderRow(maxTasks, taskSuffix);
	const tbody = students.map(v => {
		const cols = resultTableTaskCell(curChapter.tasks, maxTasks, (task, i) => {
			if(task.type !== "embedding"){ return ""; }
			const taskId = task.taskId;
			const res = getUserTaskResults(goal, v.id, taskId);
			const c = (res.level > 1) ? "studentResultHigh"
				: (res.level > 0) ? "studentResultMedium"
					: "studentResultLow";
			return `<td class="${res.tries > 0 ? c : "studentResultNil"}" student-id="${v.id}" task-id="${taskId}" task-name="${chapterId}.${i+1}">${res.tries || ""}</td>`;
		});
		return `<tr><th>${v.reminder || v.loginName}</th>` + cols + "</tr>";
	}).join("");

	const html = `<table class="workingTaskResultOverview">
			<thead><tr>
				${thead}
			</tr></thead>
			<tbody>
				${tbody}
			</tbody>
		</table>
		<button class="back-button">Zur&uuml;ck zur &Uuml;bersicht</button>`;
	ele.innerHTML = html;
	const backCB = () => showChapterResults(chapterId);
	ele.querySelectorAll(".workingTaskResultOverview [task-id],.workingTaskResultOverview [chapter-id]").forEach(ele => {
		ele.addEventListener("click", () => {
			const taskId = ele.getAttribute("task-id");
			const taskName = ele.getAttribute("task-name");
			if(taskId && taskName){
				showTeacherStudentTaskDetails(students.map(s => s.id), taskId, taskName, backCB);
			}
		});
	});
	ele.querySelectorAll(".back-button").forEach(b => b.addEventListener("click", showModuleResults));
	setTimeout(refreshChapterResults, 20 * 1000);
};

export const refreshModuleResults = async (modal: HTMLElement) => {
	await syncXApi();
	const ele = modal.querySelector<HTMLElement>(".module-results");
	if(!ele){
		return;
	}
	const cat = getPageLearningGoal();
	const goal = await getLearningGoal(cat);
	if(!goal){
		throw new Error(`Unknown Learning Goal ${cat}`);
	}
	const students = await getCourseStudents();
	const allTasks = getAllPageTasks();
	const maxTasks = allTasks.reduce((acc, v) => Math.max(acc, v.tasks.length), 0);

	const taskSuffix = configuration.target === "netbook" ? ". Schritt" : ". Teil";
	const thead = resultTableHeaderRow(maxTasks, taskSuffix);
	const tbody = allTasks.map((v) => {
		const cols = resultTableTaskCell(v.tasks, maxTasks, (task, i) => {
			if(task.type !== "embedding"){ return ""; }
			const taskId = task.taskId;
			const res = getTaskResults(goal, taskId);
			const c = (res.average > 0.85) ? "studentResultHigh"
				: (res.average > 0.5) ? "studentResultMedium"
					: "studentResultLow";
			return `<td class="${res.studentsFinished > 0 ? c : "studentResultNil"}" chapter-id="${v.chapter}" task-id="${taskId}" task-name="${v.chapter}.${i+1}">${res.studentsFinished ? `${res.studentsFinished} SuS` : ""}</td>`;
		});
		return `<tr><th chapter-id="${v.chapter}">${v.chapter}</th>` + cols + "</tr>";
	}).join("");

	const html = `<table class="workingTaskResultOverview">
			<thead><tr>
				${thead}
			</tr></thead>
			<tbody>
				${tbody}
			</tbody>
		</table>`;
	ele.innerHTML = html;

	const backCB = showModuleResults;
	const to = setTimeout(() => refreshModuleResults(modal), 20 * 1000);
	ele.querySelectorAll(".workingTaskResultOverview [task-id],.workingTaskResultOverview [chapter-id]").forEach(ele => {
		ele.addEventListener("click", () => {
			const chapterId = ele.getAttribute("chapter-id");
			const taskId = ele.getAttribute("task-id");
			const taskName = ele.getAttribute("task-name");
			clearTimeout(to);
			if(chapterId){
				showChapterResults(chapterId);
				return;
			}
			if(taskId && taskName){
				showTeacherStudentTaskDetails(students.map(s => s.id), taskId, taskName, backCB);
				return;
			}
		});
	});
};

export const showChapterResults = (chapterId: string) => {
	const title = document.querySelector<HTMLElement>("#header-center h3")?.innerText || "Ergebnisse zum Modul";
	const modal = showModal(`<div class="moduleResults">
		<h1>${title} - E ${chapterId}</h1>
		<div class="module-results">Ergebnisse werden ausgewertet</div>
	</div>`, "wide-box");
	refreshChapterResults(modal, chapterId);
};

export const showModuleResults = () => {
	const title = document.querySelector<HTMLElement>("#header-center h3")?.innerText || "Ergebnisse zum Modul";
	const modal = showModal(`<div class="moduleResults">
		<h1>${title}</h1>
		<div class="module-results">Ergebnisse werden ausgewertet</div>
	</div>`, "wide-box");
	refreshModuleResults(modal);
};

/*
 * Returns whether the current page could have module results by checking whether there are any exercises in the current chapter.
 */
export const couldHaveModuleResults = ():boolean => {
	const cat = getPageLearningGoal();
	if(!cat){
		return false;
	}
	const allTasks = getAllPageTasks();
	if(allTasks.length <= 0){
		return false;
	}
	return true;
};

export const initModuleResults = () => {
	if(!couldHaveModuleResults()){
		return;
	}
	const button = document.createElement("button");
	button.id = "module-results-button";
	const header = document.querySelector("#header-left");
	if(!header){
		throw new Error("Can't query header");
	}
	button.onclick = showModuleResults;
	header.append(button);
};

setTimeout(() => {
	if(configuration.ugmEndpoint){
		initModuleResults();
	}
},0);
